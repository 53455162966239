import React, { Component } from 'react';
import axios from 'axios';
import  LoginSVG  from "./loginSVG"

export class Login extends Component {
   
    constructor() {
        super();
        this.state = {};
        this.state = {
            UserName: '',
            Password: '',
            loading: false
        };

    }
    componentDidMount() {

    }

    login = () => {

        if (this.state.UserName === '' && this.state.Password === '') { return false;}

        var self = this;
        var url = '/HAData/GetLogin';
        self.setState({ loading: true });
        let params = {
            "UserID": this.state.UserName,
            "Password": this.state.Password
        }
        let esc = encodeURIComponent

        let query = Object.keys(params)

            .map(k => esc(k) + '=' + esc(params[k]))

            .join('&')



        url = url + '?' + query;

        axios.get(url)

            .then(function (response) {
                //handle success
                if (response.data !== "" && response.data !== null) {
                    console.log(response.data);

                    var login = response.data;

                    if (login === true) {
                        self.props.setAuthentication(login);
                       
                    }
                    else {
                        alert('Login failed');
                    }

                    self.setState({ loading: false });


                }
                else {
                    self.setState({ data: [], loading: false });
                    alert('Login failed');

                }

            })
            .catch(function (response) {
                //handle error
                self.setState({ loading: false });
                alert(response);
                console.log(response);
            });

    }



    render() {

        return (
            <div className="container-fluid">
                <div className='row' style={{ marginTop: '35px' }}>
                    <div className='col-md-12' style={{ textAlign:'center' }}>
                        <h3> HerpAlert Admin </h3>
                        <h6>Authorized user only</h6>

                    </div>

                </div>
                <div className='row' >
                    <div className='col-md-4 mx-auto w-100 p-3 text-center'>
                        <div><LoginSVG /></div>
                            <div className="form-group">
                               
                                <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Email"
                                    onChange={(e) => this.setState({ UserName: e.target.value })} value={this.state.UserName}

                                />
                            </div>
                            <div className="form-group">
                               
                                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"
                                    onChange={(e) => this.setState({ Password: e.target.value })} value={this.state.Password}
                                />
                            </div>


                            <button type="button" className="btn btn-primary" onClick={this.login} disabled={this.state.loading === true ? true : false} >{this.state.loading === true ? 'Authenticating...' : 'Login'}</button>
                        


                    </div>

                </div>





            </div>
        );
    }
}
