import React, { Component } from 'react';
import axios from 'axios';
import schema from '../../Schema/adminConfig.json';
import { AwesomeDataTable } from '../../components/AwesomeDataTable';

export class Admin extends Component {
    configFile = "adminConfig.json";
    constructor() {
        super();
        this.state = {};
        this.state = { data: [], loading: true };

    }
    componentWillMount() {
        
    }
    componentDidMount() {


    }

    onSelect = (data) => {
        //alert('onSelect');
    }
    onInsert = (data) => {
        //alert('onInsert');
    }
    onUpdate = (data) => {

        //var jSTR = JSON.stringify(data);
        //alert('onUpdate');
    }
    onDelete = (data) => {
        //alert('onDelete');
    }

    render() {


        return (
            <div>

              

                <div style={{}}>

                    <AwesomeDataTable
                       ConfigFile='adminConfig.json'
                        NUID={this.props.NUID}
                        Data={[]}
                        Columns={schema.columns}
                        onSelect={this.onSelect}
                        onInsert={this.onInsert}
                        onUpdate={this.onUpdate}
                        onDelete={this.onDelete}

                    />

                </div>

            </div>
        );
    }

}

