import React, { Component } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export class Home extends Component {
    static displayName = Home.name;
    constructor() {
        super();
        this.state = {};
        this.state = {
            data: [],
            series_daily: [],
            cat_daily: [],
            series_monthly: [],
            cat_monthly: [],
            refill_series_daily: [],
            refill_cat_daily: [],
            refill_series_monthly: [],
            refill_cat_monthly: [],
            series_line_yearly: [],
            series_line_monthly: [],
            cat_line_yearly: [],
            cat_line_monthly:[],
            loading: true
        };

    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

        this.LoadCaseGraphData('Daily');
        this.LoadRefillCaseGraphData('Daily');
        this.LoadCaseGraphData('Monthly');
        this.LoadRefillCaseGraphData('Monthly');
        this.LoadLineTrendGraphData('Monthly');
        this.LoadLineTrendGraphData('Yearly');

        
        
        }

    LoadCaseGraphData = (rptType) => {

        var self = this;
        var url = '/HAData/getCaseGraphData';
        self.setState({ loading: true });
        let param = {
            "rptType": rptType
        }

        axios.get(url + '?rptType=' + rptType)

            .then(function (response) {
                //handle success
                if (response.data !== "" && response.data !== null) {
                    console.log(response.data);
                    self.setState({ loading: false });
                    if (rptType === 'Daily') { self.setState({ series_daily: response.data.data, cat_daily: response.data.catagory }); }
                    else if (rptType === 'Monthly') { self.setState({ series_monthly: response.data.data, cat_monthly: response.data.catagory });}
                   
                    

                }
                else {
                    self.setState({ data: [], loading: false });

                }
               
            })
            .catch(function (response) {
                //handle error
                self.setState({ loading: false });
                alert(response);
                console.log(response);
            });

    }

    LoadRefillCaseGraphData = (rptType) => {

        var self = this;
        var url = '/HAData/getRefillCaseGraphData';
        self.setState({ loading: true });
        let param = {
            "rptType": rptType
        }

        axios.get(url + '?rptType=' + rptType)

            .then(function (response) {
                //handle success
                if (response.data !== "" && response.data !== null) {
                    console.log(response.data);
                    self.setState({ loading: false });
                    if (rptType === 'Daily') { self.setState({ refill_series_daily: response.data.data, refill_cat_daily: response.data.catagory }); }
                    else if (rptType === 'Monthly') { self.setState({ refill_series_monthly: response.data.data, refill_cat_monthly: response.data.catagory }); }



                }
                else {
                    self.setState({ data: [], loading: false });

                }

            })
            .catch(function (response) {
                //handle error
                self.setState({ loading: false });
                alert(response);
                console.log(response);
            });

    }

    LoadLineTrendGraphData = (rptType) => {

        var self = this;
        var url = '/HAData/getLineTrendGraphData';
        self.setState({ loading: true });
        let param = {
            "rptType": rptType
        }

        axios.get(url + '?rptType=' + rptType)

            .then(function (response) {
                //handle success
                if (response.data !== "" && response.data !== null) {
                    console.log(response.data);
                    self.setState({ loading: false });
                    if (rptType === 'Monthly') { self.setState({ series_line_monthly: response.data.data, cat_line_monthly: response.data.catagory }); }
                    else if (rptType === 'Yearly') { self.setState({ series_line_yearly: response.data.data, cat_line_yearly: response.data.catagory }); }



                }
                else {
                    self.setState({ data: [], loading: false });

                }

            })
            .catch(function (response) {
                //handle error
                self.setState({ loading: false });
                alert(response);
                console.log(response);
            });

    }

    render() {
        const options_daily = {
            chart: {
                type: 'column'
            },
            title: {
                text: '7 Days Cases Trend (New Cases)'
            },
            xAxis: {
                categories: this.state.cat_daily
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Cases'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            //legend: {
            //    align: 'right',
            //    x: -30,
            //    verticalAlign: 'top',
            //    y: 25,
            //    floating: true,
            //    backgroundColor:
            //        Highcharts.defaultOptions.legend.backgroundColor || 'white',
            //    borderColor: '#CCC',
            //    borderWidth: 1,
            //    shadow: false
            //},
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: this.state.series_daily
        };

        const options_monthly = {
            chart: {
                type: 'column'
            },
            title: {
                text: '6 Months Trend (New Cases)'
            },
            xAxis: {
                categories: this.state.cat_monthly
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Cases'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            //legend: {
            //    align: 'right',
            //    x: -30,
            //    verticalAlign: 'top',
            //    y: 25,
            //    floating: true,
            //    backgroundColor:
            //        Highcharts.defaultOptions.legend.backgroundColor || 'white',
            //    borderColor: '#CCC',
            //    borderWidth: 1,
            //    shadow: false
            //},
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: this.state.series_monthly
        };

        const options_refill_daily = {
            chart: {
                type: 'column'
            },
            title: {
                text: '7 Days Cases Trend (Refills)'
            },
            xAxis: {
                categories: this.state.refill_cat_daily
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Refills'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            //legend: {
            //    align: 'right',
            //    x: -30,
            //    verticalAlign: 'top',
            //    y: 25,
            //    floating: true,
            //    backgroundColor:
            //        Highcharts.defaultOptions.legend.backgroundColor || 'white',
            //    borderColor: '#CCC',
            //    borderWidth: 1,
            //    shadow: false
            //},
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: this.state.refill_series_daily
        };

        const options_refill_monthly = {
            chart: {
                type: 'column'
            },
            title: {
                text: '6 Months Trend (Refills)'
            },
            xAxis: {
                categories: this.state.refill_cat_monthly
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Refills'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            //legend: {
            //    align: 'right',
            //    x: -30,
            //    verticalAlign: 'bottom',
            //    y: 25,
            //    floating: true,
            //    backgroundColor:
            //        Highcharts.defaultOptions.legend.backgroundColor || 'white',
            //    borderColor: '#CCC',
            //    borderWidth: 1,
            //    shadow: false
            //},
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: this.state.refill_series_monthly
        };

        const options_lineGraph_monthly = {
            chart: {
                type: 'line'
            },
            title: {
                text: 'Monthly Cases Trend'
            },
            
            xAxis: {
                categories: this.state.cat_line_monthly
            },
            yAxis: {
                title: {
                    text: 'Total'
                }
            },
            plotOptions: {
                //line: {
                //    dataLabels: {
                //        enabled: true
                //    },
                //    enableMouseTracking: false
                //}
            },
            credits: {
                enabled: false
            },
            series: this.state.series_line_monthly
        };
        const options_lineGraph_yearly = {
            chart: {
                type: 'line'
            },
            title: {
                text: 'Yearly Cases Trend'
            },

            xAxis: {
                categories: this.state.cat_line_yearly
            },
            yAxis: {
                title: {
                    text: 'Total'
                }
            },
            plotOptions: {
                //line: {
                //    dataLabels: {
                //        enabled: true
                //    },
                //    enableMouseTracking: false
                //}
            },
            credits: {
                enabled: false
            },
            series: this.state.series_line_yearly
        };
    return (
      <div>
            <div class='row'>
                <div class='col-md-6'>
                    <HighchartsReact highcharts={Highcharts} options={options_lineGraph_monthly} />
                </div>
                <div class='col-md-6'>
                    <HighchartsReact highcharts={Highcharts} options={options_lineGraph_yearly} />
                </div>
            </div>
            <div class='row'>
                <div class='col-md-6'>
                    <HighchartsReact highcharts={Highcharts} options={options_daily} />
                </div>
                <div class='col-md-6'>
                    <HighchartsReact highcharts={Highcharts} options={options_refill_daily} />
                </div>
            </div>
                <div class='row'>
                <div class='col-md-6'>
                    <HighchartsReact highcharts={Highcharts} options={options_monthly} />
                </div>
                <div class='col-md-6'>
                    <HighchartsReact highcharts={Highcharts} options={options_refill_monthly} />
                </div>
                </div>
                
                
               
            
        </div>
    );
  }
}
