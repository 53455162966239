import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Login } from './components/Login';
import { Admin } from './components/Admin/Admin';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

    constructor() {
        super();
       
        this.state = {
            Authenticated: false,
            
        };

    }
    setAuthentication = (flag) => {
        this.setState({ Authenticated: flag })
        
    }
    // <Route exact path='/' component={Login} />
  render () {
      return (
          <div>
              {
                  this.state.Authenticated === false ?
                      <Login setAuthentication={this.setAuthentication} />

                      :

                      <Layout>
                          <Route exact path='/' component={Admin} />
                          <Route exact path='/Home' component={Home} />
                          <Route path='/Admin' component={Admin} />
                      </Layout>
              }



          </div>
    );
  }
}
