import React from "react";

function LoginSVG() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="220.139"
            data-name="Layer 1"
            viewBox="0 0 870 720.139"
        >
            <path
                fill="#f2f2f2"
                d="M831.092 704.187c-11.138-9.411-17.904-24.28-16.13-38.753s12.764-27.78 27.02-30.854 30.503 5.435 34.833 19.36c2.383-26.847 5.128-54.818 19.402-77.68 12.924-20.701 35.308-35.514 59.568-38.164s49.803 7.359 64.933 26.507 18.835 46.985 8.238 68.969c-7.806 16.195-22.188 28.247-37.257 38.052A240.452 240.452 0 01827.246 707.6z"
                transform="translate(-165 -189.93)"
            ></path>
            <path
                fill="#fff"
                d="M996.728 546.01a393.414 393.414 0 00-54.826 54.442 394.561 394.561 0 00-61.752 103.194c-1.112 2.725 3.312 3.91 4.412 1.216a392.342 392.342 0 01115.401-155.617c2.285-1.86-.97-5.08-3.235-3.235z"
                transform="translate(-165 -189.93)"
            ></path>
            <path
                fill="#f2f2f2"
                d="M445.067 701.63c15.299-12.927 24.591-33.348 22.154-53.228s-17.53-38.156-37.11-42.378-41.897 7.465-47.844 26.59c-3.272-36.873-7.044-75.291-26.648-106.693-17.751-28.433-48.497-48.778-81.818-52.417s-68.404 10.107-89.185 36.407-25.87 64.534-11.315 94.729c10.722 22.243 30.476 38.797 51.172 52.264 66.03 42.965 147.94 60.885 225.878 49.415"
                transform="translate(-165 -189.93)"
            ></path>
            <path
                fill="#fff"
                d="M217.567 484.373a540.355 540.355 0 0175.304 74.776 548.076 548.076 0 0159.386 87.891 545.835 545.835 0 0125.43 53.846c1.527 3.743-4.55 5.372-6.06 1.671a536.36 536.36 0 00-49.01-92.727 539.734 539.734 0 00-65.728-81.198 538.44 538.44 0 00-43.766-39.815c-3.138-2.555 1.332-6.978 4.444-4.444z"
                transform="translate(-165 -189.93)"
            ></path>
            <path
                fill="#f2f2f2"
                d="M789.5 708.93h-365v-374.5c0-79.677 64.822-144.5 144.5-144.5h76c79.678 0 144.5 64.823 144.5 144.5z"
                transform="translate(-165 -189.93)"
            ></path>
            <path
                fill="#ccc"
                d="M713.5 708.93h-289v-374.5a143.382 143.382 0 0127.596-84.944c.664-.904 1.326-1.797 2.008-2.68a144.466 144.466 0 0130.755-29.851c.66-.48 1.322-.952 1.994-1.424a144.16 144.16 0 0131.472-16.459c.66-.25 1.334-.501 2.007-.742a144.02 144.02 0 0131.108-7.336c.658-.09 1.333-.16 2.008-.23a146.288 146.288 0 0131.106 0c.673.07 1.348.14 2.014.231a143.995 143.995 0 0131.1 7.335c.673.24 1.346.492 2.009.743a143.8 143.8 0 0131.106 16.216c.672.461 1.344.933 2.007 1.404a145.987 145.987 0 0118.383 15.564 144.305 144.305 0 0112.725 14.551c.68.88 1.342 1.774 2.005 2.677a143.382 143.382 0 0127.597 84.946z"
                transform="translate(-165 -189.93)"
            ></path>
            <circle cx="525" cy="335.5" r="16" fill="#6c63ff"></circle>
            <path
                fill="#ffb8b8"
                d="M594.599 507.783L582.339 507.783 576.506 460.495 594.601 460.496 594.599 507.783z"
            ></path>
            <path
                fill="#2f2e41"
                d="M573.582 504.28h23.643v14.887h-38.53a14.887 14.887 0 0114.887-14.887z"
            ></path>
            <path
                fill="#ffb8b8"
                d="M655.599 507.783L643.339 507.783 637.506 460.495 655.601 460.496 655.599 507.783z"
            ></path>
            <path
                fill="#2f2e41"
                d="M634.582 504.28h23.643v14.887h-38.53a14.887 14.887 0 0114.887-14.887z"
            ></path>
            <path
                fill="#ffb8b8"
                d="M698.098 528.6a10.743 10.743 0 014.51-15.843l41.676-114.866 20.507 11.191-47.585 109.77a10.8 10.8 0 01-19.108 9.748zM814.336 550.184a10.743 10.743 0 01-2.893-16.216l-12.91-121.51 23.337 1.067 5.366 119.52a10.8 10.8 0 01-12.9 17.14z"
                transform="translate(-165 -189.93)"
            ></path>
            <circle cx="612.106" cy="162.123" r="24.561" fill="#ffb8b8"></circle>
            <path
                fill="#6c63ff"
                d="M814.18 522.55h-74.047l.089-.577c.133-.861 13.197-86.439 3.562-114.436a11.813 11.813 0 016.069-14.583c13.772-6.486 40.208-14.471 62.52 4.909a28.234 28.234 0 019.46 23.396z"
                transform="translate(-165 -189.93)"
            ></path>
            <path
                fill="#6c63ff"
                d="M754.354 448.181l-33.336-6.763 15.626-37.03a13.997 13.997 0 0127.106 6.998zM797.05 460.739l-2.004-45.942c-1.52-8.636 3.424-16.8 11.028-18.134 7.605-1.33 15.031 4.66 16.558 13.36l7.533 42.927z"
                transform="translate(-165 -189.93)"
            ></path>
            <path
                fill="#2f2e41"
                d="M811.716 517.05c11.915 45.376 13.214 103.069 10 166l-16-2-29-120-16 122-18-1c-5.377-66.03-10.613-122.716-2-160zM793.29 371.035c-4.583 4.88-13.092 2.26-13.69-4.407a8.055 8.055 0 01.01-1.556c.31-2.954 2.015-5.635 1.607-8.754a4.59 4.59 0 00-.84-2.149c-3.652-4.889-12.223 2.187-15.669-2.239-2.113-2.714.371-6.987-1.25-10.02-2.14-4.004-8.479-2.029-12.454-4.222-4.423-2.44-4.158-9.225-1.247-13.352 3.55-5.034 9.776-7.72 15.923-8.107s12.253 1.275 17.993 3.512c6.52 2.54 12.987 6.053 17 11.787 4.88 6.973 5.35 16.348 2.91 24.502-1.485 4.96-6.552 11.02-10.294 15.005z"
                transform="translate(-165 -189.93)"
            ></path>
            <path
                fill="#3f3d56"
                d="M1004.982 709.574H266.688a1.19 1.19 0 010-2.381h738.294a1.19 1.19 0 010 2.381z"
                transform="translate(-165 -189.93)"
            ></path>
            <path
                fill="#fff"
                d="M634 600.43H504a6.465 6.465 0 01-6.5-6.415V303.846a6.465 6.465 0 016.5-6.415h130a6.465 6.465 0 016.5 6.415v290.17a6.465 6.465 0 01-6.5 6.415z"
                transform="translate(-165 -189.93)"
            >

            </path>
            <path fill="#ccc" d="M332.5 201.39H475.5V203.39H332.5z"></path>
            <path fill="#ccc" d="M333 315.5H476V317.5H333z"></path>
            <path fill="#ccc" d="M377.5 107.5H379.5V411.5H377.5z"></path>
            <path fill="#ccc" d="M427.5 107.5H429.5V411.5H427.5z"></path>
        </svg>
    );
}

export default LoginSVG;
