import React, { Component } from 'react';
import axios from 'axios';
import moment from "moment";
//import { Col, Grid, Row } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { ToggleButton } from 'primereact/togglebutton';
import { Checkbox } from 'primereact/checkbox';
import { Growl } from 'primereact/growl';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ListBox } from 'primereact/listbox';
import { Card } from 'primereact/card';

export class AwesomeDataTable extends Component {

    constructor() {
        super();


        this.state = {};
        this.state = {
            data: [], columns: [], jsonData: null, jsonData2: null, colOptions: [], selectedData: [], lookup: [], loading: true, visible: false, totalRecords: 0, totalFilteredValue: 0,
            windowWidth: window.innerWidth, windowHeight: window.innerHeight, displayDialogView: false, displayDialogViewIcon: false, search_pharmacyZip: '91101', search_pharmacyName: 'cvs',
            filter_token: null, filter_fname: null, filter_lname: null, filter_email: null, filter_phone: null
        };


    }

    componentWillMount() {

        this.setState({ columns: this.props.Columns });

        //Load Lookup

        var self = this;
        var url = '/HAData/SelectLookup';


        let param = {
            "data": [],
            "configFile": this.props.ConfigFile,
            "nuid": this.props.NUID
        }

        axios.post(url, param)

            .then(function (response) {
                //handle success
                if (response.data !== "" && response.data !== null) {

                    // self.setState({ lookup: response.data });
                    self.props.Columns.forEach((col) => {
                        if (typeof (col.lookup) !== 'undefined' && col.lookup !== '') {
                            var lookup = response.data.filter(val => val.groupName === col.lookup);;

                            self.setState({ lookup: { ...self.state.lookup, [col.lookup]: lookup } });
                        }
                    });

                }
                else {
                    //self.setState({ data: [] });

                }

            })
            .catch(function (response) {
                //handle error
                alert(response);
                console.log(response);
            });


    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

        this.LoadData(false);

        if (this.state.columns.length > 0) {
            let colOptions = [];

            this.state.columns.map((col, i) => {

                return colOptions.push({ label: col.header, value: col });

            })

            this.setState({ colOptions: colOptions });
        }


    }

    componentDidUpdate(prevProps, prevState) {
        if (this.dt) {
            let filteredData = this.dt.processData();

            if (filteredData && filteredData.length !== this.state.totalFilteredValue)
                this.setState({ totalFilteredValue: filteredData ? filteredData.length : 0 })
        }
    }
    handleResize = (e) => {
        this.setState({ windowWidth: window.innerHeight, windowWidth: window.innerHeight });
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    LoadData = (isFilter=false) => {
       // filter_token: null, filter_fname: null, filter_lname: null, filter_email: null, filter_phone: null
        var self = this;
        var url = '/HAData/Select';
        self.setState({ loading: true });
        let data = {};
        if (isFilter === true)
        {
            data = { 'token': this.state.filter_token, 'first_name': this.state.filter_fname, 'last_name': this.state.filter_lname, 'email': this.state.filter_email, 'phone': this.state.filter_phone }
        }
        else {
            data = { 'token': null, 'first_name': null, 'last_name': null, 'email': null, 'phone': null }
        }
        let param = {
            "data": data,
            "configFile": this.props.ConfigFile,
            "nuid": this.props.NUID
        }

        axios.post(url, param)

            .then(function (response) {
                //handle success
                if (response.data !== "" && response.data !== null) {
                    console.log(response.data);
                    self.setState({ data: response.data, loading: false });
                    self.setState({ totalRecords: response.data.length, totalFilteredValue: response.data.length });

                }
                else {
                    self.setState({ data: [], loading: false });

                }
                // var cols = Object.keys(response.data[0]);
                //self.setState({ columns: cols });

            })
            .catch(function (response) {
                //handle error
                self.setState({ loading: false });
                alert(response);
                console.log(response);
            });

    }

    onColumnToggle = (event) => {
        this.setState({ columns: event.value });
    }

    updateCase = (actionType) => {
        var self = this;
        let obj = [...this.state.data];



            var url = '/HAData/Update';

            let param = {
                "data": self.state.row,
                "actionType": actionType,
                "configFile": self.props.ConfigFile,
                "nuid": self.props.NUID
            }
            axios.post(url, param)

                .then(function (response) {
                    //handle success


                    if (response.data === 'Done') {

                        let doseSpotID = self.state.row["dose_spot_patient_id"];
                        if (actionType === 'UpdatePharmacy' && (doseSpotID !== '' && doseSpotID !== null)) { self.DoseSpotAPI_AddPharmacy(); }
                        
                       
                        obj[self.findSelectedDataIndex()] = self.state.row;
                        self.props.onUpdate(self.state.row);
                        self.setState({ data: obj });

                        self.growl.show({ severity: 'success', summary: 'Success', detail: 'Updated' });
                    }
                    else {

                        alert(response.data);
                        self.growl.show({ severity: 'error', summary: 'Error while saving', detail: 'Not Updated' });
                    }




                })
                .catch(function (response) {
                    //handle error
                    alert(response);
                    console.log(response);
                });

        
    }

    save = () => {
        var self = this;
        let obj = [...this.state.data];
        if (this.newRow) { //Insert
            var url = '/HAData/Save';

            let param = {
                "data": Object.assign({}, self.state.row),
                "configFile": self.props.ConfigFile,
                "nuid": self.props.NUID
            }
            axios.post(url, param)

                .then(function (response) {
                    //handle success


                    if (response.data == 'Done') {

                        obj.push(self.state.row);
                        self.props.onInsert(self.state.row);
                        self.setState({ data: obj, selectedData: [], row: null, displayDialog: false });
                        self.growl.show({ severity: 'success', summary: 'Success', detail: 'Saved' });
                    }
                    else {

                        alert(response.data);
                        self.growl.show({ severity: 'error', summary: 'Error while saving', detail: 'Not Saved' });
                    }




                })
                .catch(function (response) {
                    //handle error
                    alert(response);
                    console.log(response);
                });

        }

        else { // Update
            var url = '/HAData/Update';

            let param = {
                "data": self.state.row,
                "configFile": self.props.ConfigFile,
                "nuid": self.props.NUID
            }
            axios.post(url, param)

                .then(function (response) {
                    //handle success


                    if (response.data === 'Done') {

                        obj[self.findSelectedDataIndex()] = self.state.row;
                        self.props.onUpdate(self.state.row);
                        self.setState({ data: obj, selectedData: [], row: null, displayDialog: false });
                        self.growl.show({ severity: 'success', summary: 'Success', detail: 'Updated' });
                    }
                    else {

                        alert(response.data);
                        self.growl.show({ severity: 'error', summary: 'Error while saving', detail: 'Not Updated' });
                    }




                })
                .catch(function (response) {
                    //handle error
                    alert(response);
                    console.log(response);
                });

        }



    }

    delete = () => {
        var self = this;
        let index = this.findSelectedDataIndex();

        var url = '/HAData/Delete';

        let param = {
            "data": this.state.row,
            "configFile": this.props.ConfigFile,
            "nuid": this.props.NUID
        }
        axios.post(url, param)

            .then(function (response) {
                //handle success


                if (response.data == 'Done') {

                    self.setState({
                        data: self.state.data.filter((val, i) => i !== index),
                        selectedData: [],
                        row: null,
                        displayDialog: false
                    });

                    self.growl.show({ severity: 'success', summary: 'Success', detail: 'Deleted' });
                    self.props.onDelete(self.state.row);

                }
                else {

                    alert(response.data);
                    self.growl.show({ severity: 'error', summary: 'Error while saving', detail: 'Not Updated' });
                }




            })
            .catch(function (response) {
                //handle error
                alert(response);
                console.log(response);
            });




    }

    findSelectedDataIndex = () => {
        return typeof (this.state.selectedData.length) === 'undefined' ? this.state.data.indexOf(this.state.selectedData) : this.state.data.indexOf(this.state.selectedData[0]);

    }

    updateProperty = (property, value) => {
        let row = this.state.row;
        row[property] = value;
        this.setState({ row: row });
    }

    onRowSelect = (e) => {
        if (this.state.selectedData.length > 1) { return false; } // only for signle select
        if (this.state.selectedData.length < 1) { this.setState({ selectedData: e.data }) }
        this.newRow = false;
        this.setState({
            displayDialog: true,
            row: Object.assign({}, e.data)
        });

        this.props.onSelect(Object.assign({}, e.data));

    }
    onRowSelect2 = (rowData) => {
        if (this.state.selectedData.length > 1) { return false; } // only for signle select
        if (this.state.selectedData.length < 1) { this.setState({ selectedData: rowData }) }
        this.newRow = false;
        this.setState({
            displayDialogCaseEdit: true,
            row: Object.assign({}, rowData)
        });

        this.props.onSelect(Object.assign({}, rowData));

    }
    addNew = () => {

        if (this.state.selectedData.length > 1) { return false; } // only for signle select

        this.newRow = true;
        var row_ = this.state.row;
        var rowCopy;
        if (typeof row_ === 'undefined' || row_ === null) {
            rowCopy = [];
        }
        else {
            rowCopy = JSON.parse(JSON.stringify(row_))
        }

        this.state.columns.map((col, i) => {

            rowCopy[col.field] = '';

        })
        this.setState({
            row: rowCopy,
            displayDialog: true
        });
    }

    export = () => {
        this.dt.exportCSV();
    }

    onFilterChange = (event, colName) => {
        this.dt.filter(event.value, colName, 'in');
        this.setState({ [colName]: event.value });
    }

    FilterLoad = (e) => {
        let filters = Object.entries(e.filters)
        if (filters.length > 0) {

            let filterName = filters[0][0];
            let filterValue = filters[0][1].value;
            this.dt.filter(filterValue, filterName, 'contains');
        }
    }
    replaceUndefinedOrNull = (key, value) => {
        if (value === null || value === undefined) {
            return undefined;
        }

        return value;
    }
    showDialogView = (e, data, link) => {

        var self = this;
        var url = '/HAData/selectDoseSpotAPI';

        let param = {
            "data": data,
            "configFile": 'doseSpotAPI.json',
            "apiName": link,
            "nuid": this.props.NUID
        }

        axios.post(url, param)

            .then(function (response) {
                //handle success
                if (response.data !== "" && response.data !== null) {

                    var destinationObj = {};
                    Object.assign(destinationObj, response.data);
                    Object.keys(destinationObj).forEach((key) => (destinationObj[key] === null || (typeof (destinationObj[key].length) !== 'undefined' && destinationObj[key].length <= 0)) && delete destinationObj[key]);

                    destinationObj = JSON.stringify(destinationObj, self.replaceUndefinedOrNull);
                    destinationObj = JSON.parse(destinationObj);

                    self.setState({ jsonData: destinationObj });




                    //Object.keys(destinationObj).forEach(function (item) {
                    //    console.log(item);
                    //    (typeof (destinationObj[item].length) === 'undefined') ?
                    //        Object.keys(destinationObj[item]).forEach(function (value) {
                    //            console.log(value);
                    //            console.log(destinationObj[item][value]);

                    //        })
                    //        :
                    //        destinationObj[item].forEach(function (value) {
                    //            console.log(value);
                    //            Object.keys(value).forEach(function (val) {
                    //                console.log(val);
                    //                console.log(value[val]);

                    //            })
                    //        })
                    //})

                    self.setState({
                        displayDialogView: true
                    });

                }
                else {
                    self.setState({ jsonData: null });

                }
                // var cols = Object.keys(response.data[0]);
                //self.setState({ columns: cols });

            })
            .catch(function (response) {
                //handle error
                self.setState({ jsonData: null });
                alert(response);
                console.log(response);
            });


    }
    showDialogViewIcon = (e, data, col) => {

        var self = this;
        if (col === 'note') {
            self.setState({ dr_note: data[col], iconType: 'dr_note' });
        }
        else if (col === 'followup_questions') {
            self.setState({ dr_comments: data[col], iconType: 'dr_comments' });
        }
        else {
            var destinationObj;
            destinationObj = data[col];
            destinationObj = JSON.parse(destinationObj);
            destinationObj = JSON.stringify(destinationObj, self.replaceUndefinedOrNull);
            destinationObj = JSON.parse(destinationObj);

            //Object.keys(destinationObj).forEach(function (item) {
            //                console.log(item);
            //                (typeof (destinationObj[item].length) === 'undefined') ?
            //                    Object.keys(destinationObj[item]).forEach(function (value) {
            //                        console.log(value);
            //                        console.log(destinationObj[item][value]);

            //                    })
            //                    :
            //                    destinationObj[item].forEach(function (value) {
            //                        console.log(value);
            //                        Object.keys(value).forEach(function (val) {
            //                            console.log(val);
            //                            console.log(value[val]);

            //                        })
            //                    })
            //            })

            self.setState({ jsonData2: destinationObj, iconType: 'questionaire' });
        }
        self.setState({
            displayDialogViewIcon: true
        });

    }
    checkboxTemplate(rowData, column) {
        return <Checkbox checked={rowData[column.field] === 1 ? true : false} readOnly={true}></Checkbox>;
    }

    linkTemplate = (rowData, column) => {
        return <button type="button" className="btn btn-link" onClick={(e) => this.showDialogView(e, rowData, column.linkCol)}>{rowData[column.field]}</button>;
    }
    iconTemplate = (rowData, column) => {
        return <Button type="button" icon="pi pi-external-link" className="p-button-secondary" onClick={(e) => this.showDialogViewIcon(e, rowData, column.field)}></Button>;

    }
    actionBodyTemplate = (rowData, column) => {
        return (
            <Button type="button" icon="pi pi-cog" className="p-button-secondary" onClick={() => this.onRowSelect2(rowData)}></Button>
        );
    }
    pharmacyTemplate(option) {
        const det = option.detail;
        const Title = det.StoreName;
        const SubTitle = 'Pharmacy ID: ' + det.PharmacyId
        return (
            <div key={'listDiv_' + det.PharmacyId} className="p-clearfix">
                 
                <Card title={Title} subTitle={SubTitle}>
                    <div>Address: {det.Address1}, {det.Address2}, {det.City}, {det.State}, {det.ZipCode}</div>
                    <div>Phone: {det.PrimaryPhone}</div>
                 
                </Card>
               
            </div>
        );
    }
    DateTemplate(rowData, column) {
        return (rowData[column.field] === null || rowData[column.field] === '') ? '' : moment(rowData[column.field]).format("MM-DD-YYYY");
    }
    dateFormat(dt_) {
        if (dt_ !== null && dt_ !== '') {
            var d = new Date(dt_);
            var day = d.getDate();
            var month = d.getMonth() + 1;
            var year = d.getFullYear();

            if (day < 10) {
                day = '0' + day;
            }

            if (month < 10) {
                month = '0' + month;
            }

            let dt = month + '-' + day + '-' + year;
            return new Date(dt);
        }
        else { return null; }

    }

    DoseSpotAPI_SearchPharmacy = () => {
        let link = 'searchPharmacies';
        var self = this;
        let zip = self.state.search_pharmacyZip;
        let pharmacyName = self.state.search_pharmacyName
        let state = self.state.search_pharmacyZip
        let data = {'zip': zip};
        
        var url = '/HAData/selectDoseSpotAPI';

        data = JSON.stringify(data, self.replaceUndefinedOrNull);
        data = JSON.parse(data);

        let param = {
            "data": data,
            "configFile": 'doseSpotAPI.json',
            "apiName": link,
            "nuid": this.props.NUID
        }

        axios.post(url, param)

            .then(function (response) {
                //handle success
                if (response.data !== "" && response.data !== null) {

                    var destinationObj = {};
                    Object.assign(destinationObj, response.data);
                    Object.keys(destinationObj).forEach((key) => (destinationObj[key] === null || (typeof (destinationObj[key].length) !== 'undefined' && destinationObj[key].length <= 0)) && delete destinationObj[key]);

                    destinationObj = JSON.stringify(destinationObj, self.replaceUndefinedOrNull);
                    destinationObj = JSON.parse(destinationObj);

                    const pharmacyData = [];
                    destinationObj.Items.map((item, i) => {

                        return pharmacyData.push({ label: item.StoreName, value: item.PharmacyId, detail: item });

                    })
                       
                                        

                    self.setState({ pharmacyData: pharmacyData });
                                                           

                }
                else {
                    self.setState({ pharmacyData: [] });

                }
          

            })
            .catch(function (response) {
                //handle error
                self.setState({ jsonData: [] });
                alert(response);
                console.log(response);
            });

    }

    DoseSpotAPI_AddPharmacy = () => {
        let link = 'addPharmacies';
        var self = this;
        let data = this.state.row; //{ 'zip': zip };

        var url = '/HAData/selectDoseSpotAPI';

        data = JSON.stringify(data, self.replaceUndefinedOrNull);
        data = JSON.parse(data);

        let param = {
            "data": data,
            "configFile": 'doseSpotAPI.json',
            "apiName": link,
            "nuid": this.props.NUID
        }

        axios.post(url, param)

            .then(function (response) {
                //handle success
                if (response.data !== "" && response.data !== null) {

                    var destinationObj = {};
                    Object.assign(destinationObj, response.data);
                    Object.keys(destinationObj).forEach((key) => (destinationObj[key] === null || (typeof (destinationObj[key].length) !== 'undefined' && destinationObj[key].length <= 0)) && delete destinationObj[key]);

                    destinationObj = JSON.stringify(destinationObj, self.replaceUndefinedOrNull);
                    destinationObj = JSON.parse(destinationObj);

                    if (destinationObj.Result.ResultCode === 'OK') {


                    }
                    else { alert(destinationObj.Result.ResultCode + ' : ' + destinationObj.Result.ResultDescription) }

                }
                else { }
                })
            .catch(function (response) {
                //handle error
                self.setState({ jsonData: [] });
                alert(response);
                console.log(response);
            });

    }
    DoseSpotAPI_CreateDoseSpotPatientID = () => {
        let link = 'addPatient';
        var self = this;
        let data = this.state.row;

        var url = '/HAData/selectDoseSpotAPI';

        data = JSON.stringify(data, self.replaceUndefinedOrNull);
        data = JSON.parse(data);

        let param = {
            "data": data,
            "configFile": 'doseSpotAPI.json',
            "apiName": link,
            "nuid": this.props.NUID
        }

        axios.post(url, param)

            .then(function (response) {
                //handle success
                if (response.data !== "" && response.data !== null) {

                    var destinationObj = {};
                    Object.assign(destinationObj, response.data);
                    Object.keys(destinationObj).forEach((key) => (destinationObj[key] === null || (typeof (destinationObj[key].length) !== 'undefined' && destinationObj[key].length <= 0)) && delete destinationObj[key]);

                    destinationObj = JSON.stringify(destinationObj, self.replaceUndefinedOrNull);
                    destinationObj = JSON.parse(destinationObj);
                   
                    if (destinationObj.Result.ResultCode === 'OK')
                    {
                        var doseSpotID = destinationObj.Id;
                        //self.updateProperty("dose_spot_patient_id", doseSpotID);
                        let row = self.state.row;
                        row["dose_spot_patient_id"] = doseSpotID;
                        self.setState({ row: row });

                        setInterval(
                            self.DoseSpotAPI_AddPharmacy(),
                            1000);
                        
                      



                    }
                    else { alert(destinationObj.Result.ResultCode + ' : ' + destinationObj.Result.ResultDescription)}
                    


                }
                else {
                   // self.setState({ pharmacyData: [] });

                }


            })
            .catch(function (response) {
                //handle error
                self.setState({ jsonData: [] });
                alert(response);
                console.log(response);
            });

    }

    render() {

        let data = this.state.data;

        let dynamicColumns = this.state.columns.map((col, i) => {

            const opt = col.multiFilter === true ? [...new Set(data.map(data => data[col.field]))] : [];
            const colOptions = [];

            opt.map(val => {
                return colOptions.push({ label: val, value: val });
            });

            return (
                col.visible === true ?
                    col.type === 'icon' ?
                        <Column key={col.field + i}
                            field={col.field}
                            header={col.header}
                            style={{ textAlign: 'center', width: '8em' }}
                            body={this.iconTemplate}
                        />
                        :
                        <Column key={col.field + i}
                            field={col.field}
                            header={col.header}
                            linkCol={typeof (col.linkURL) === 'undefined' || col.linkURL === null ? '' : col.linkURL}
                            sortable={true}
                            filter={true}
                            filterMatchMode="contains"
                            body={
                                col.type === 'boolean' ?
                                    this.checkboxTemplate : col.type === 'datetime' ? this.DateTemplate
                                        : col.type === 'link' ? this.linkTemplate
                                            : null
                            }
                            filterElement={col.multiFilter === true ?
                                <div>
                                    <MultiSelect ref={'filterMultiSelect_' + col.field} maxSelectedLabels={1}
                                        //styleClass="ui-column-filter"
                                        style={{ width: '100%', height: 'auto', fontSize: '12px' }}
                                        // className="p-column-filter"
                                        filter={true} value={this.state[col.field]}
                                        options={colOptions}
                                        onChange={(e) => this.onFilterChange(e, col.field)}
                                        appendTo={document.body}

                                    />
                                </div>

                                : null
                            }

                            style={{ width: col.width, wordWrap: 'break-word' }} />
                    : null
            );

        });


        let header = <div style={{ textAlign: 'left' }}>

            <MultiSelect value={this.state.columns} options={this.state.colOptions} onChange={this.onColumnToggle} />
            <span style={{ marginLeft: '10px' }}>Total Record: {this.state.totalFilteredValue}</span>
            <Button icon="pi pi-refresh" onClick={() => this.LoadData(false)} style={{ 'float': 'right', marginLeft: '5px' }} />
            <Button type="button" icon="pi pi-external-link" iconPos="right" label="CSV" onClick={this.export} style={{ 'float': 'right', marginLeft: '5px' }}></Button>
            <Button style={{ float: 'right', marginLeft: '5px', display: 'none' }} label="Add" icon="pi pi-plus" onClick={this.addNew} />
            <InputText
                type="search"
                onInput={e => this.setState({ first: 0, globalFilter: e.target.value })}
                placeholder="Global Search"
                style={{ float: 'right', marginLeft: '5px' }}
            />
        </div>;
        let footer = <div className="p-clearfix" style={{ width: '100%' }}>

        </div>;

        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            {this.newRow ? null : <Button label="Delete" icon="pi pi-times" onClick={this.delete} />}
            <Button label="Save" icon="pi pi-check" onClick={this.save} />
        </div>;
        
        return (
            <div>
                <Growl ref={(el) => this.growl = el} position="topright" baseZIndex={10002} />
                <div style={{ margin:'20px' }}>
                    <div className='row' style={{ marginTop: '30px' }}>
                        <div className='col-md-1'>
                            <span>Advance Search: </span>
                        </div>
                        <div className='col-md-2'>
                            <span className="p-float-label">
                                <InputText id="in" style={{ width: '100%' }} onChange={e => this.setState({ filter_token: e.target.value })} value={this.state.filter_token} />
                                <label htmlFor="in">Token</label>

                            </span>
                        </div>
                        <div className='col-md-2'>
                            <span className="p-float-label">
                                <InputText id="in" style={{ width: '100%' }} onChange={e => this.setState({ filter_fname: e.target.value })} value={this.state.filter_fname} />
                                <label htmlFor="in">First Name</label>

                            </span>
                        </div>
                        <div className='col-md-2'>
                            <span className="p-float-label">
                                <InputText id="in" style={{ width: '100%' }} onChange={e => this.setState({ filter_lname: e.target.value })} value={this.state.filter_lname} />
                                <label htmlFor="in">Last Name</label>

                            </span>
                        </div>
                        <div className='col-md-2'>
                            <span className="p-float-label">
                                <InputText id="in" style={{ width: '100%' }} onChange={e => this.setState({ filter_email: e.target.value })} value={this.state.filter_email} />
                                <label htmlFor="in">Email</label>

                            </span>
                        </div>
                        <div className='col-md-2'>
                            <span className="p-float-label">
                                <InputText id="in" style={{ width: '100%' }} onChange={e => this.setState({ filter_phone: e.target.value })} value={this.state.filter_phone} />
                                <label htmlFor="in">Phone</label>

                            </span>
                        </div>
                        <div className='col-md-1'>
                            <Button icon="pi pi-search" label='Search' onClick={() => this.LoadData(true)} style={{ 'float': 'right', marginLeft: '5px' }} />
                        </div>
                    </div>
                </div>
                <div>
                    <DataTable ref={(el) => this.dt = el} value={this.state.data}
                        paginator={true}
                        rowsPerPageOptions={[10, 50, 100]}
                        rows={10}
                        //first={this.state.first}
                        //onPage={(e) => this.setState({ first: e.first })}
                        // resizableColumns={true}
                        globalFilter={this.state.globalFilter}
                        responsive={true}
                        header={header}
                        footer={footer}
                        filters={this.state.filters}
                        scrollable={true}
                        scrollHeight={(this.state.windowHeight - 350) + 'px'}
                        loading={this.state.loading}
                        loadingIcon='pi pi-spinner'
                        emptyMessage="No records found"
                        totalRecords={this.state.totalRecords}
                        //selectionMode="single"
                        selection={this.state.selectedData}
                        onSelectionChange={e => this.setState({ selectedData: e.value })}
                        onRowDoubleClick={this.onRowSelect}
                        onFilter={e => this.setState({ filters: e.filters })} //{(e) => this.FilterLoad(e)}
                    >
                        <Column body={this.actionBodyTemplate} headerStyle={{ width: '4em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
                        {dynamicColumns}
                    </DataTable>

                    <Dialog
                        header={this.state.row && (this.state.row['token'] === null ? '' : this.state.row['token'])}
                        visible={this.state.displayDialog}
                        position={'center'}
                        contentStyle={{ maxHeight: (this.state.windowHeight - 250), width: '60vw', overflow: "auto" }}
                        baseZIndex={10001}
                        modal={true}
                        footer={dialogFooter}

                        onHide={() => this.setState({ displayDialog: false })}>
                        {
                            //  this.state.row &&

                            <div className='row'>
                                {

                                    this.state.columns.map((col, index) => (

                                        col.editable === true ?

                                            <div key={col.field} className='col-md-6'>
                                                <div className="p-col-4" style={{ padding: '.2em' }}><label htmlFor={col.field}>{col.header}</label></div>
                                                <div className="p-col-8" style={{ padding: '.2em' }}>
                                                    {
                                                        //lookup
                                                        (typeof (col.lookup) !== 'undefined' && col.lookup !== '') ?
                                                            <Dropdown id={col.field} key={col.field} options={typeof (this.state.lookup[col.lookup]) !== 'undefined' ? this.state.lookup[col.lookup] : [{ label: 'No Data', value: 'No Data' }]} onChange={(e) => { this.updateProperty(col.field, e.target.value) }} value={this.state.row && (this.state.row[col.field] === null ? '' : this.state.row[col.field])} />
                                                            :
                                                            col.type === 'string' ?
                                                                <InputText id={col.field} key={col.field} onChange={(e) => { this.updateProperty(col.field, e.target.value) }} value={this.state.row && (this.state.row[col.field] === null ? '' : this.state.row[col.field])} />
                                                                : col.type === 'note' ?
                                                                    <InputTextarea rows={5} cols={30} onChange={(e) => { this.updateProperty(col.field, e.target.value) }} value={this.state.row && (this.state.row[col.field] === null ? '' : this.state.row[col.field])} />
                                                                    : col.type === 'datetime' ?
                                                                        <Calendar dateFormat="mm/dd/yy" onChange={(e) => { this.updateProperty(col.field, e.target.value) }} value={this.state.row && (this.state.row[col.field] === null ? '' : this.dateFormat(this.state.row[col.field]))} showButtonBar={true}></Calendar>
                                                                        : col.type === 'boolean' ?
                                                                            this.newRow ?
                                                                                <ToggleButton checked={true} onChange={(e) => { this.updateProperty(col.field, e.value) }} />
                                                                                :
                                                                                <ToggleButton checked={this.state.row && (this.state.row[col.field] === null ? false : this.state.row[col.field])} onChange={(e) => { this.updateProperty(col.field, e.value) }} />
                                                                            :
                                                                            <InputText id={col.field} key={col.field} onChange={(e) => { this.updateProperty(col.field, e.target.value) }} value={this.state.row && (this.state.row[col.field] === null ? '' : this.state.row[col.field])} />
                                                    }

                                                </div>
                                            </div>
                                            : null
                                    ))

                                }

                            </div>


                        }

                    </Dialog>

                    <Dialog
                        header={this.state.row && (this.state.row['token'] === null ? '' : this.state.row['token'])}
                        visible={this.state.displayDialogView}
                        position={'center'}
                        contentStyle={{ maxHeight: (this.state.windowHeight - 250), width: '60vw', overflow: "auto" }}
                        baseZIndex={10001}
                        modal={true}
                        onHide={() => this.setState({ displayDialogView: false })}>
                        {
                            this.state.jsonData &&

                            <div className='row'>
                                {

                                    Object.keys(this.state.jsonData).map((item, i) => (

                                        (typeof (this.state.jsonData[item].length) === 'undefined') ?
                                            Object.keys(this.state.jsonData[item]).map((value, index) => (

                                                <div className={index === 0 ? 'col-md-12' : 'col-md-4'}>
                                                    {index === 0 ? <div><h2>{item}</h2> <hr /> </div> : null}
                                                    <div className='row' style={{ fontSize: '16px' }}>
                                                        <div className='col-md-6'>{value}</div>
                                                        <div className='col-md-6'>
                                                            <span className="badge badge-secondary" style={{ padding: '5px' }}>
                                                                {this.state.jsonData[item][value]}
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>

                                            ))
                                            :
                                            this.state.jsonData[item].map((value, index) => (
                                                (typeof (value) !== 'undefined' || value !== null) ?
                                                    Object.keys(value).map((val, indx) => (
                                                        (typeof (value[val].length) === 'undefined') ?
                                                            <div className={indx === 0 ? 'col-md-12' : 'col-md-4'}>
                                                                {indx === 0 ? <div><h2>{item}</h2> <hr /> </div> : null}
                                                                <div className='row' style={{ fontSize: '16px' }}>
                                                                    <div className='col-md-6'>{val}</div>
                                                                    <div className='col-md-6'>
                                                                        <span className="badge badge-secondary" style={{ padding: '5px' }}>
                                                                            {value[val]}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                    )) : null
                                            ))
                                    ))

                                }

                            </div>

                        }

                    </Dialog>

                    <Dialog
                        header={this.state.row && (this.state.row['token'] === null ? '' : this.state.row['token'])}
                        visible={this.state.displayDialogViewIcon}
                        position={'center'}
                        contentStyle={{ maxHeight: (this.state.windowHeight - 250), width: '60vw', overflow: "auto" }}
                        baseZIndex={10001}
                        modal={true}
                        onHide={() => this.setState({ displayDialogViewIcon: false })}>
                        {
                            this.state.iconType &&
                            this.state.iconType === 'dr_note' ?
                                this.state.dr_note &&
                                <div>
                                    {this.state.dr_note}
                                </div>
                                : this.state.iconType === 'dr_comments' ?
                                    this.state.dr_comments &&
                                    <div>
                                        {this.state.dr_comments}
                                    </div>
                                    :

                            this.state.jsonData2 &&

                            <div className='row'>
                                {

                                    Object.keys(this.state.jsonData2).map((item, i) => (

                                        <div className='col-md-6'>
                                            <div className='row' style={{ fontSize: '16px' }}>
                                                <div className='col-md-4'>{item}</div>
                                                <div className='col-md-8'>
                                                    <span className="badge badge-secondary" style={{ padding: '5px' }}>
                                                        {this.state.jsonData2[item]}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))


                                }

                            </div>

                        }

                    </Dialog>

                    <Dialog
                        header={this.state.row && (this.state.row['token'] === null ? '' : this.state.row['token'])}
                        visible={this.state.displayDialogCaseEdit}
                        position={'center'}
                        contentStyle={{ maxHeight: (this.state.windowHeight - 250), width: '70vw', overflow: "auto" }}
                        baseZIndex={10001}
                        modal={true}
                        footer={''}

                        onHide={() => this.setState({ displayDialogCaseEdit: false })}>
                        {
                            //  this.state.row &&
                            <div>
                                <h2>Patient Info</h2>
                                <hr />
                                <div className='row' style={{ marginTop: '30px' }}>

                                    <div className='col-md-4'>
                                        <span className="p-float-label">
                                            <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('first_name', e.target.value) }} value={this.state.row && (this.state.row['first_name'] === null ? '' : this.state.row['first_name'])} />
                                            <label htmlFor="in">First Name</label>
                                        </span>
                                    </div>
                                    <div className='col-md-4'>
                                        <span className="p-float-label">
                                            <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('middle_initial', e.target.value) }} value={this.state.row && (this.state.row['middle_initial'] === null ? '' : this.state.row['middle_initial'])} />
                                            <label htmlFor="in">Middle Initial</label>
                                        </span>
                                    </div>
                                    <div className='col-md-4'>
                                        <span className="p-float-label">
                                            <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('last_name', e.target.value) }} value={this.state.row && (this.state.row['last_name'] === null ? '' : this.state.row['last_name'])} />
                                            <label htmlFor="in">Last Name</label>
                                        </span>
                                    </div>
                                </div>

                                <div className='row' style={{ marginTop: '30px' }}>

                                    <div className='col-md-4'>
                                        <span className="p-float-label">
                                            <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('dob', e.target.value) }} value={this.state.row && (this.state.row['dob'] === null ? '' : this.state.row['dob'])} />
                                            <label htmlFor="in">DOB</label>

                                        </span>
                                    </div>
                                    <div className='col-md-4'>
                                        <span className="p-float-label">
                                            <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('email', e.target.value) }} value={this.state.row && (this.state.row['email'] === null ? '' : this.state.row['email'])} />
                                            <label htmlFor="in">Email</label>
                                        </span>

                                    </div>
                                    <div className='col-md-4'>
                                        <span className="p-float-label">
                                            <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('phone', e.target.value) }} value={this.state.row && (this.state.row['phone'] === null ? '' : this.state.row['phone'])} />
                                            <label htmlFor="in">Phone</label>
                                        </span>
                                    </div>
                                </div>

                                <div className='row' style={{ marginTop: '30px' }}>

                                    <div className='col-md-4'>
                                        <span className="p-float-label">
                                            <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('address_1', e.target.value) }} value={this.state.row && (this.state.row['address_1'] === null ? '' : this.state.row['address_1'])} />
                                            <label htmlFor="in">Address_1</label>
                                        </span>
                                    </div>
                                    <div className='col-md-4'>
                                        <span className="p-float-label">
                                            <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('address_2', e.target.value) }} value={this.state.row && (this.state.row['address_2'] === null ? '' : this.state.row['address_2'])} />
                                            <label htmlFor="in">Address_2</label>
                                        </span>
                                    </div>
                                    <div className='col-md-4'>
                                        <span className="p-float-label">
                                            <label htmlFor="in">patient_state</label>
                                            <Dropdown id='patient_state_lookup' key='patient_state_lookup'
                                                options={typeof (this.state.lookup['states']) !== 'undefined' ? this.state.lookup['states'] : [{ label: 'No Data', value: 'No Data' }]}
                                                onChange={(e) => { this.updateProperty('patient_state_id', e.target.value) }}
                                                value={this.state.row && (this.state.row['patient_state_id'] === null ? '' : this.state.row['patient_state_id'].toString())} />
                                           
                                        </span>
                                    </div>

                                </div>

                                <div className='row' style={{ marginTop: '30px' }}>
                                    <div className='col-md-4'>
                                        <span className="p-float-label">
                                            <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('patient_zip', e.target.value) }} value={this.state.row && (this.state.row['patient_zip'] === null ? '' : this.state.row['patient_zip'])} />
                                            <label htmlFor="in">patient_zip</label>
                                        </span>
                                    </div>
                                </div>
                                <hr />
                                <div className="ui-dialog-buttonpane p-clearfix">
                                    <Button label="Update Patient Info" icon="pi pi-check" onClick={() => this.updateCase('UpdatePatientInfo')} />
                                </div>

                                <hr />

                                <h2>Change Case Status and Doctor</h2>
                                <hr />
                                <div className='row' style={{ marginTop: '30px', padding: '10px' }}>

                                    <div className='col-md-2'>

                                        {

                                            <Dropdown id='case_status_lookup' key='case_status_lookup'
                                                options={typeof (this.state.lookup['CaseStatus']) !== 'undefined' ? this.state.lookup['CaseStatus'] : [{ label: 'No Data', value: 'No Data' }]}
                                                onChange={(e) => { this.updateProperty('status', e.target.value) }}
                                                value={this.state.row && (this.state.row['status'] === null ? '' : this.state.row['status'])} />

                                        }

                                    </div>
                                    <div className='col-md-2'>
                                       
                                        <span className="p-float-label">

                                            <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('dose_spot_patient_id', e.target.value) }} value={this.state.row && (this.state.row['dose_spot_patient_id'] === null ? '' : this.state.row['dose_spot_patient_id'])} />
                                            <label htmlFor="in">doseSpot_patient_id</label>
                                        </span>
                                        <Button label="Create DoseSpot ID" className="p-button-raised p-button-warning" style={{ marginTop: '5px' }}
                                            onClick={this.DoseSpotAPI_CreateDoseSpotPatientID}
                                        />
                                    </div>
                                    <div className='col-md-2' style={{ display: 'none' }}>
                                        <span className="p-float-label">
                                            <InputText id="in" disabled style={{ width: '90%' }} onChange={(e) => { this.updateProperty('drName', e.target.value) }} value={this.state.row && (this.state.row['drName'] === null ? '' : this.state.row['drName'])} />
                                            <label htmlFor="in">DrName</label>
                                        </span>
                                    </div>
                                    <div className='col-md-2'>
                                        {
                                            <div>
                                                <Dropdown id='dermatologist_lookup' key='dermatologist_lookup'
                                                    options={typeof (this.state.lookup['doctors']) !== 'undefined' ? this.state.lookup['doctors'] : [{ label: 'No Data', value: 'No Data' }]}
                                                    onChange={(e) => { this.updateProperty('dermatologist_id', e.target.value) }}
                                                    value={this.state.row && ((this.state.row['dermatologist_id'] === '' || this.state.row['dermatologist_id'] === null) ? '' : this.state.row['dermatologist_id'].toString())}
                                                    filter={true} filterPlaceholder="Select Dr" filterBy="label,value" showClear={true}
                                                />

                                            </div>
                                        }


                                    </div>


                                </div>
                                <hr />
                                <div className="ui-dialog-buttonpane p-clearfix">
                                    <Button label="Update Status & Doctor" icon="pi pi-check" onClick={() => this.updateCase('UpdateCaseStatusAndAssignDoctor')} />
                                </div>
                                <hr />

                                <h2>Change Pharmacy</h2>
                                <hr />
                                <div className='row' style={{ padding: '10px' }}>
                                    <div className='col-md-6'>
                                        <div className='row' style={{ marginTop: '30px' }}>

                                            <div className='col-md-12'>
                                                <span className="p-float-label">
                                                    <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('pharmacy_id', e.target.value) }} value={this.state.row && (this.state.row['pharmacy_id'] === null ? '' : this.state.row['pharmacy_id'])} />
                                                    <label htmlFor="in">pharmacy_id</label>
                                                </span>
                                            </div>


                                        </div>
                                        <div className='row' style={{ marginTop: '30px' }}>

                                            <div className='col-md-12'>
                                                <span className="p-float-label">
                                                    <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('pharmacy_city', e.target.value) }} value={this.state.row && (this.state.row['pharmacy_city'] === null ? '' : this.state.row['pharmacy_city'])} />
                                                    <label htmlFor="in">pharmacy_city</label>
                                                </span>
                                            </div>


                                        </div>
                                        <div className='row' style={{ marginTop: '30px' }}>

                                            <div className='col-md-12'>
                                                <span className="p-float-label">
                                                    <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('pharmacy_state', e.target.value) }} value={this.state.row && (this.state.row['pharmacy_state'] === null ? '' : this.state.row['pharmacy_state'])} />
                                                    <label htmlFor="in">pharmacy_state</label>

                                                </span>
                                            </div>

                                        </div>
                                        <div className='row' style={{ marginTop: '30px' }}>

                                            <div className='col-md-12'>
                                                <span className="p-float-label">
                                                    <InputText id="in" style={{ width: '90%' }} onChange={(e) => { this.updateProperty('pharmacy_zip', e.target.value) }} value={this.state.row && (this.state.row['pharmacy_zip'] === null ? '' : this.state.row['pharmacy_zip'])} />
                                                    <label htmlFor="in">pharmacy_zip</label>

                                                </span>
                                            </div>

                                        </div>
                                        <hr />
                                        <div className="ui-dialog-buttonpane p-clearfix">
                                            <Button label="Update Pharmacy" icon="pi pi-check" onClick={() => this.updateCase('UpdatePharmacy')} />
                                        </div>
                                    </div>
                                    <div className='col-md-6' style={{ backgroundColor: 'lightGray' }}>
                                        <h4>Search Pharmacy</h4>
                                        <div className='row' style={{ marginTop: '30px' }}>
                                            <div className='col-md-2'>
                                                <span className="p-float-label">
                                                    <InputText id="in" style={{ width: '90%' }} onChange={e => this.setState({ search_pharmacyZip: e.target.value })} value={this.state.search_pharmacyZip} />
                                                    <label htmlFor="in">zip</label>

                                                </span>
                                            </div>
                                            <div className='col-md-4' >
                                                {
                                                   
                                                    <Dropdown id='search_state_lookup' key='search_state_lookup'
                                                        options={typeof (this.state.lookup['states']) !== 'undefined' ? this.state.lookup['states'] : [{ label: 'No Data', value: 'No Data' }]}
                                                        onChange={(e) => { this.setState({ search_state_id: e.target.value }) }}
                                                        value={this.state.search_state_id} />

                                                }
                                               
                                            </div>
                                            <div className='col-md-4'>
                                                <span className="p-float-label">
                                                    <InputText id="in" style={{ width: '90%' }} onChange={e => this.setState({ search_pharmacyName: e.target.value })} value={this.state.search_pharmacyName} />
                                                    <label htmlFor="in">pharmacy Name</label>

                                                </span>
                                            </div>
                                            <div className='col-md-2'>
                                                <Button label="Search" onClick={this.DoseSpotAPI_SearchPharmacy} />
                                            </div>
                                        </div>
                                        <div className='row' style={{marginTop:'10px'}}>
                                            <div className='col-md-12'>
                                                {
                                                    typeof (this.state.pharmacyData) !== 'undefined' ?
                                                        <ListBox value={this.state.selectedPharmacy}
                                                            options={this.state.pharmacyData}
                                                            onChange={(e) => this.setState({ selectedPharmacy: e.value })}
                                                            itemTemplate={this.pharmacyTemplate}
                                                            filter={true}
                                                            filterPlaceholder="Search"
                                                            showClear={true}
                                                            style={{ width: '95%' }} listStyle={{ maxHeight: '160px' }}
                                                        />
                                                        :
                                                        null

                                                }
                                                
                                           </div>
                                         </div>
                                    </div>
                                </div>
                                <hr />

                              
                            </div>
                        }

                    </Dialog>
                </div>

            </div>
        );
    }

}

